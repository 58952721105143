@import "./styles/variables";
@import "./styles/text";

* {
  font-family: "Nunito", sans-serif !important;
  // font-family: "Poppins", sans-serif !important;
  // font-family: "Montserrat", sans-serif !important;
  color: $black1;
}

html {
  scroll-behavior: smooth;
}

a {
  outline: none;
}

ul,
ol {
  padding-left: 0;
}

hr {
  border: 0;
  border-top: 2px solid $black1;
  opacity: 1;
  border-radius: 5px;
}

button {
  cursor: pointer;
}

// * CUSTOM CLASSES STARTS HERE
.c-pointer {
  cursor: pointer;
}
// * CUSTOM CLASSES ENDS HERE

// ? WHATSAPP STARTS HERE
.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 60px;
  right: 30px;
  // background-color: #35bc48;
  color: #fff;
  border-radius: 50px;
  text-align: right;
  font-size: 30px;
  z-index: 100;
}
// ? WHATSAPP ENDS HERE

// * FANCYBOX STYLES STARTS HERE
.fancybox {
  &__counter {
    color: $white1;
    span {
      color: $white1;
    }
  }
  &__toolbar__items {
    button {
      background: $grey1;
      margin-left: 1rem;
    }
  }
  &__nav {
    button {
      background: $grey1;
    }
  }
  &__thumbs {
    .carousel__slide .fancybox__thumb::after {
      // border-color: $brown4;
    }
  }
}
// * FANCYBOX STYLES ENDS HERE

// ! MATERIAL CHECKBOX STARTS HERE
.MuiFormControlLabel-label,
.MuiMenuItem-root {
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.02em !important;
  font-family: "Poppins", sans-serif !important;
}
// ! MATERIAL CHECKBOX ENDS HERE

// ? PAGE STYLES STARTS HERE
.page {
  margin-top: 5rem;
}

@media screen and (max-width: 992px) {
  .page {
    margin-top: 4.5rem;
  }
}
@media screen and (max-width: 576px) {
  .whatsapp {
    right: 30px;
  }
}
// ? PAGE STYLES ENDS HERE
