@import "../../styles/global";

.navbar-backbox-one {
  background: $brown1;
  width: 40vw;
  height: 60vh;
  // height: 35vh;
  // width: 100vw;
  position: absolute;
  bottom: 0vh;
  right: 0;
  z-index: -1;
}
.navbar-backbox-two {
  background: $brown1;
  width: 30vw;
  height: 90vh;
  // height: 35vh;
  // width: 100vw;
  position: absolute;
  top: 0;
  left: 5vw;
  z-index: -1;
  border-radius: 0 0 10px 10px;
}
.navbar {
  transition: all 0.2s ease-in;
  // box-shadow: inset 3px 0px rgb(0, 0, 0 / 0.2);
  // background: linear-gradient(
  //   180deg,
  //   rgba(0, 0, 0, 0.6) 0%,
  //   rgba(0, 0, 0, 0.01) 100%
  // );
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 0.5) 0%,
  //   rgba(255, 255, 255, 0) 100%
  // );
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 0.75) 0%,
  //   rgba(255, 255, 255, 0.25) 100%
  // );
  background: linear-gradient(
    180deg,
    rgba(244, 246, 248, 0.65) 0%,
    rgba(244, 246, 248, 0) 100%
  );
  // background: linear-gradient(180deg, #f4f6f8 0%, rgba(244, 246, 248, 0) 100%);
  // background: linear-gradient(
  //   180deg,
  //   rgba(0, 0, 0, 0.5) 0%,
  //   rgba(244, 246, 248, 0) 100%
  // );
  &-box,
  &-button {
    z-index: 1000 !important;
  }
  &-box-circle {
    // background: $black1;
    // height: 64px;
    // width: 64px;
  }
  &-logo {
    font-size: $font10;
    color: $black1;
    z-index: 1000 !important;
    position: relative;
    &__mobile {
      color: $black1;
      z-index: 1000 !important;
      position: relative;
      font-family: "DM Serif Display", serif !important;
      letter-spacing: 2px;
      font-size: $font10;
    }
    &__image {
      z-index: 1000 !important;
      position: relative;
    }
  }
  &-home-scrolled {
    background: $grey1;
    // background: $brown1;
    // border-bottom: 1px solid $grey1 !important;
  }
  &-about-scrolled {
    background: $brown1;
    // border-bottom: 1px solid $brown1 !important;
  }
}
.nav {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: -100vh;
  left: 0;
  z-index: -1;
  visibility: hidden;
  // background-color: $brown1;

  background: $grey1;
  //   background-color: $white1;
  // border-bottom-right-radius: 100%;
  // border-bottom-left-radius: 100%;
  transition: all 650ms cubic-bezier(1, 0, 0, 1);
  &__list {
    list-style: none;
    // width: 100vw;
    // height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 7vw !important;
    // flex-wrap: wrap;
    li {
      margin: 8px 0;
      text-transform: uppercase;
    }
    &-item {
      font-family: "DM Serif Display", serif !important;
      font-size: $font12;
      text-decoration: none;
      color: $black1;
      padding: 1rem;
      &:hover {
        //background: linear-gradient(to top, #ffe838, #fd57bf);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
        color: $black1;
      }
    }
  }

  &-image {
    padding-right: 7vw !important;
    img {
      max-height: 65vh !important;
      width: auto;
      border-radius: 10px;
    }
  }
}

.nav.open {
  top: 0;
  left: 0;
  border-radius: initial;
  overflow: hidden;
  z-index: 100;
  visibility: visible;
}

/* HAMBURGER ICON START */
.menu-toggler {
  //   position: fixed;
  top: 16px;
  right: 20px;
  width: 32px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  z-index: 1000;
  transition: transform 650ms ease-out;
  cursor: pointer;
  &:hover {
    .bar {
      background: $black1 !important;
    }
  }
}
.menu-toggler.open {
  transform: rotate(-45deg);
  //   position: fixed;
  .bar {
    background: $black1 !important;
  }
  &:hover {
    .bar {
      background: $black1 !important;
    }
  }
}
.bar {
  //background: linear-gradient(to right, #ffe838, #fd57bf);
  background: $black1;
  width: 100%;
  height: 4px;
  border-radius: 0.8rem;
}
.bar.half {
  width: 50%;
}
.bar.start {
  transform-origin: right;
  transition: transform 650ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
}
.open .bar.start {
  transform: rotate(-450deg) translateX(0.5rem);
}
.bar.end {
  align-self: flex-end;
  transform-origin: left;
  transition: transform 650ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
}
.open .bar.end {
  transform: rotate(-450deg) translateX(-0.5rem);
}
.bar-active {
  background: $black1;
}
/* HAMBURGER ICON END */

@media screen and (max-width: 768px) {
  .nav {
    &__list {
      padding-left: 0 !important;
    }
  }
}
