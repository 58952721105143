@import "./variables";

// .page {
//   margin-top: 4.5rem;
// }

// .page-title {
//   font-family: "Poppins", sans-serif !important;
//   font-size: 1.625rem;
//   letter-spacing: 0.04em;
//   font-weight: 800;
// }

.btn {
  &-black {
    background: $black1;
    color: $white1;
    outline: none;
    border: 2px solid $black1;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.1s ease-in;
    font-size: $font2;
    letter-spacing: 1px;
    // border-radius: 5px;
    &:hover {
      background: transparent;
      color: $black1;
      transition: all 0.1s ease-in;
    }
  }
  &-black-grey {
    background: $black1;
    color: $white1;
    outline: none;
    border: 2px solid $black1;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.1s ease-in;
    font-size: $font2;
    letter-spacing: 1px;
    // border-radius: 5px;
    &:hover {
      background: $grey2;
      color: $black1;
      transition: all 0.1s ease-in;
      border: 2px solid $grey2;
    }
  }
  &-white {
    background: $white1;
    color: $black1;
    outline: none;
    border: 2px solid $white1;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.1s ease-in;
    font-size: $font2;
    letter-spacing: 1px;
    // border-radius: 5px;
    &:hover {
      background: transparent;
      color: $white1;
      transition: all 0.1s ease-in;
    }
  }
  &-black-outline {
    background: transparent;
    color: $black1;
    outline: none;
    border: 2px solid $black1;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.1s ease-in;
    font-size: $font2;
    letter-spacing: 1px;
    // border-radius: 5px;
    &:hover {
      background: $black1;
      color: $white1;
      transition: all 0.1s ease-in;
    }
  }
  &-white-outline {
    background: transparent;
    color: $white1;
    outline: none;
    border: 2px solid $white1;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.1s ease-in;
    font-size: $font2;
    letter-spacing: 1px;
    // border-radius: 5px;
    &:hover {
      background: $white1;
      color: $black1;
      transition: all 0.1s ease-in;
    }
  }

  &-rounded {
    border-radius: 50%;
    padding: 4rem 2rem;
  }
}

.text-white {
  color: $white1 !important;
}

.text-secondary {
  color: $grey3 !important;
}

.text-black {
  color: $black1 !important;
}

.text-brown {
  color: $brown1 !important;
}

.text-primary {
  color: $grey1 !important;
}

.bg-white {
  background: $white1 !important;
}

.bg-secondary {
  background: $grey3 !important;
}

.bg-black {
  background: $black1 !important;
}

.bg-brown {
  background: $brown1 !important;
}

.bg-primary {
  background: $grey1 !important;
}

textarea {
  border: 1.6px solid #bcbdbf !important;
  border-radius: 5px !important;
  background: transparent !important;
  outline: none !important;
  &:focus-visible {
    border: 1.6px solid $black1 !important;
  }
}
