@import "../../styles/global";

.home {
  a {
    text-decoration: none;
  }
  &-banner {
    height: calc(100vh);
    video {
      height: calc(100vh);
      max-height: 100vh;
      object-fit: cover;
    }
  }
  &-hero {
    // background: #fcfcfc;
    background: $white1;
    min-height: 100vh;
    padding-left: 8vw;
    &__heading {
      // font-family: "Allura", cursive !important;
      font-family: "Raleway", sans-serif !important;
      font-size: $font10;
      letter-spacing: 2px;
    }
    &__image {
      padding: 0 5vw !important;
      height: 100vh;
    }
  }
  &-about {
    min-height: calc(100vh - 64px);
    position: relative;
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-clip: unset;
    padding: 5rem 8vw;
    &__content {
      font-weight: 300;
    }
    &-mobile {
      top: 0;
      left: 0;
      width: calc(100vh - 60px) !important;
      z-index: -1;
    }
  }
  &-moodboard {
    background: $white1;
    &__heading {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 300px;
      h2 {
        padding-bottom: 6rem;
      }
      &-image {
        position: absolute;
        left: 65%;
        transform: translateX(-65%);
        height: 300px;
      }
    }
    &__image {
      border-radius: 10px;
    }
  }

  &-process {
    padding: 2rem 8vw 7rem 8vw;
    background: $grey1;
    &__number {
      background: $brown2;
      color: $white1;
      outline: none;
      border: 2px solid $brown2;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.1s ease-in;
      font-size: $font10;
      border-radius: 50%;
      font-weight: 400;
      height: 4.5rem;
      width: 4.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      font-family: "Raleway", sans-serif !important;
    }
    &__line {
      border-left: 4px solid $brown2;
      height: 3rem;
      border-radius: 0 0 1rem 1rem;
      position: absolute;
      left: 50%;
      margin-left: -1.5px;
      top: 4.5rem;
      z-index: 10;
    }
    &__content {
      margin-top: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &__reverse {
      .home {
        &-process {
          &__line {
            border-radius: 1rem 1rem 0 0;
            bottom: 4.5rem;
            top: auto;
            z-index: 10;
            margin-left: -2px;
          }
          &__content {
            margin-top: 0;
            margin-bottom: 3.5rem;
          }
        }
      }
    }
  }
  &-projects {
    background: $black1;
    h2 {
      color: $grey2;
    }
    &__name {
      letter-spacing: 3px;
      font-size: $font6;
      font-weight: 500;
      color: $white1;
      font-family: "Raleway", sans-serif !important;
    }
    &__type {
      letter-spacing: 1.5px;
      font-size: $font3;
      font-weight: 400;
      color: $white1;
    }
    p {
      letter-spacing: 1px;
      font-size: $font1;
      font-weight: 200;
      color: $grey2;
    }
    // &__image {
    // }
    &__large {
      &-box {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        transition: all 200ms cubic-bezier(0.164, 0.317, 0.38, 0.767);
        &-image {
          height: 360px;
          object-fit: cover;
          border-radius: 10px;
        }

        &:hover {
          opacity: 1;
          transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(-1deg, 1deg);
          transform-style: preserve-3d;
          // box-shadow: 10px 10px 15px -1px rgba(0, 0, 0, 0.21);
          backface-visibility: visible;
        }
      }
    }
    &__small {
      &-box {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        transition: all 200ms cubic-bezier(0.164, 0.317, 0.38, 0.767);
        &-image {
          height: 200px;
          object-fit: cover;
          border-radius: 10px;
        }
        &:hover {
          opacity: 1;
          transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(-1deg, 1deg);
          transform-style: preserve-3d;
          // box-shadow: 10px 10px 15px -1px rgba(0, 0, 0, 0.21);
          backface-visibility: visible;
        }
      }
    }
    &__medium {
      &-box {
        opacity: 1;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        transition: all 200ms cubic-bezier(0.164, 0.317, 0.38, 0.767);
        &-image {
          height: 300px;
          object-fit: cover;
          border-radius: 10px;
        }
        &:hover {
          opacity: 1;
          transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(-1deg, 1deg);
          transform-style: preserve-3d;
          // box-shadow: 10px 10px 15px -1px rgba(0, 0, 0, 0.21);
          backface-visibility: visible;
        }
      }
    }
  }

  &-services {
    background: $white1;
    &__box {
      background: $white1;
      position: relative;
      height: 100%;
      transition: 0.2s all ease-in-out;
      padding: 0 !important;
      &:hover {
        cursor: pointer;
        .home-services__box-overlay {
          display: none;
          transition: 0.2s all ease-in-out;
        }
        .home-services__box-name {
          text-decoration: underline;
          transition: 0.2s all ease-in-out;
          display: none;
        }
      }

      img {
        height: 260px;
        object-fit: cover;
        transition: 0.2s all ease-in-out;
      }

      &-name {
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        transition: 0.2s all ease-in-out;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-weight: 300;
        font-size: $font4;
        color: $grey1;
        z-index: 500;
      }
      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        background: $black1;
        opacity: 0.4;
        display: flex;
        transition: 0.2s all ease-in-out;
        z-index: 400;
      }
    }
    &__back {
      // background-image: url("../../assets/images/home/service5.jpg");
      // // position: relative;
      // // background-color: $brown1;
      // // background-color: $white2;
      // background-position: 50%;
      // background-size: 100%;
      // // background-size: cover;
      // background-repeat: no-repeat;
      // background-clip: unset;
      // height: 40vw;
    }
  }
}

.method {
  background: $black1 !important;
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    //margin: 0 4rem;
    &-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      background: $grey1;
      height: 400px;
      width: 300px;
      border-radius: 1rem;
      padding: 2rem 1rem 0 1rem;
      margin: 1rem;

      opacity: 1;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      transition: all 200ms cubic-bezier(0.164, 0.317, 0.38, 0.767);

      &-image {
        img {
          width: 8rem;
          height: auto;
        }
      }
      &-title {
        display: flex;
        font-size: $font8;
        align-items: baseline;
        margin: 2rem 0 1rem -3rem;
        font-weight: 600;
        .number {
          color: #ff5851;
          font-size: $font6;
          margin-right: 1rem;
          letter-spacing: 2.3px;
          font-family: "Raleway", sans-serif;
        }
      }
      &-para {
        color: $grey3;
      }
      &:hover {
        opacity: 1;
        transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(-1deg, 1deg);
        transform-style: preserve-3d;
        box-shadow: 10px 10px 15px -1px rgba(0, 0, 0, 0.21);
        backface-visibility: visible;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .method {
    &__container {
      &-box {
        height: 300px;
        width: 240px;
        padding: 1rem 0.5rem 0 0.5rem;
        margin: 1rem 0.5rem 0.5rem 0.5rem;
        &-image {
          img {
            width: 6rem;
          }
        }
        &-title {
          font-size: $font9;
          margin: 1rem 0 1rem -1rem;
          .number {
            font-size: $font7;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .method {
    &__container {
      &-box {
        width: 270px;
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .home {
    &-process {
      padding: 4rem 4vw;
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    &-hero {
      padding: 0;
      min-height: auto;
      &__heading {
        font-size: $font10;
      }
      &__image {
        height: 40vh;
      }
    }
    &-about {
      padding: 3rem 15px;
      background-image: none;
    }

    &-moodboard {
      background: $white1;
      &__heading {
        height: 200px;
        h2 {
          padding-bottom: 3rem;
        }
        &-image {
          position: absolute;
          left: 80%;
          transform: translateX(-80%);
          height: 200px;
        }
      }
    }
    &-process {
      padding: 3rem 15px;
      &__content {
        margin: 2rem 0 2rem 0;
      }
      &__line {
        height: 1.5rem;
      }

      &__reverse {
        .home {
          &-process {
            flex-direction: column-reverse;
            &__line {
              border-radius: 0 0 1rem 1rem;
              bottom: auto;
              top: 4.5rem;
            }
            &__content {
              margin: 2rem 0 2rem 0;
            }
          }
        }
      }
    }
    &-contact {
      padding: 3rem 15px;
      background-image: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .home {
    &-hero {
      &__heading {
        font-family: "Raleway", sans-serif !important;
        font-size: $font7;
        letter-spacing: 1px;
      }
    }
    &-about {
      padding: 3rem 0;
    }
    &-projects {
      &__large {
        &-box {
          &-image {
            height: 240px;
            object-fit: cover;
          }
        }
      }
      &__small {
        &-box {
          &-image {
            height: 240px;
            object-fit: cover;
          }
        }
      }
      &__medium {
        &-box {
          &-image {
            height: 240px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
