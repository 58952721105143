@import "./variables";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
  font-weight: normal;
}

h1 {
  font-size: $font20;
  font-weight: 400;
  color: $black1;
}
h2 {
  font-size: $font13;
  font-weight: 200;
  letter-spacing: 1.5px;
  color: $black1;
}
h3 {
  font-size: $font12;
  font-weight: 400;
  letter-spacing: 1px;
  color: $black1;
}
h4 {
  font-size: $font9;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: $black1;
}
h5 {
  font-size: $font7;
  font-weight: 400;
  letter-spacing: 1px;
  color: $black1;
}
h6 {
  font-size: $font4;
  font-weight: 400;
  letter-spacing: 1px;
  color: $black1;
}
p {
  font-size: $font2;
  font-weight: 400;
  letter-spacing: 1px;
  color: $black1;
}
small {
  font-size: $font1;
  letter-spacing: 1px;
  color: $black1;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: $font15;
    font-weight: 400;
    // letter-spacing: 2px;
  }
  h2 {
    font-size: $font10;
    font-weight: 300;
    letter-spacing: 1.5px;
  }
  h3 {
    font-size: $font9;
    font-weight: 400;
    letter-spacing: 1px;
  }
  h4 {
    font-size: $font7;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  h5 {
    font-size: $font5;
    font-weight: 400;
    letter-spacing: 1px;
  }
  h6 {
    font-size: $font3;
    font-weight: 400;
    letter-spacing: 1px;
  }
  p {
    font-size: $font2;
    font-weight: 300;
    letter-spacing: 1px;
  }
  small {
    font-size: $font1;
    letter-spacing: 1px;
  }
}
