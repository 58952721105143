@import "../../styles/global";
.app-contact {
  min-height: calc(100vh - 64px);
  background-image: url("../../assets/images/contact/contact-back.svg");
  position: relative;
  background-color: $grey1;
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-clip: unset;

  padding: 5rem 20vw;
  h6 {
    font-weight: 300;
    color: $black1;
  }
}

@media screen and (max-width: 768px) {
  .app-contact {
    padding: 5rem 5vw;
  }
}
