@import "../../styles/global";

.about {
  background: $brown1;
  h2 {
    color: $black1;
  }
  &-small {
    color: $black1;
    letter-spacing: 1px;
  }
  &-large {
    color: $black1;
    letter-spacing: 2px;
  }
  &-medium {
    color: $black1;
    letter-spacing: 1.8px;
  }
}
