@import "../../styles/global";

.footer {
  text-align: center;
  justify-content: center;
  &-logo {
    color: $black1;
    font-family: "DM Serif Display", serif !important;
    letter-spacing: 2px;
    font-size: $font4;
    font-weight: 800;
  }
  p {
    text-decoration: none !important;
  }
  a {
    text-decoration: none;
    color: $black1;
    &:hover {
      text-decoration: underline;
    }
  }
  .favIcon {
    path {
      color: red !important;
    }
  }
  .coffeeIcon {
    path {
      color: #c68642;
    }
  }
  .hiten {
    color: $white1;
    transition: 0.3s;
    &:hover {
      color: #ff5851;
      text-decoration: none;
    }
  }
  .designer {
    background: $black1;
    p {
      color: $grey1;
    }
  }
}
.footer-box {
  background: $grey1;
}
.footer-about {
  background: $brown1 !important;
  border-top: 1px solid $brown2 !important;
}

// .footer {
//   background: $black1;
//   height: 100vh;
//   &__heading {
//     // font-family: "Mochiy Pop One", sans-serif !important;
//     font-family: "Aclonica", sans-serif !important;
//     font-size: $font18;
//     color: $brown2;
//     span {
//       font-family: "Aclonica", sans-serif !important;
//       font-size: $font7;
//       color: $brown1;
//       font-weight: 600;
//       border-bottom: 4px solid $grey2;
//       width: max-content;
//       height: 40px;
//       margin-top: 1rem;
//     }
//   }
//   // &__box {
//   //   padding: 8px 2rem;
//   //   color: $pink1;
//   //   background: $black1;
//   //   font-size: $font12;
//   //   border-radius: 50px;
//   //   width: max-content;
//   //   font-weight: 700;
//   //   letter-spacing: 1.5px;
//   //   transform: rotate(-1.5deg);
//   // }
//   &__content {
//     color: $white1;
//     font-size: $font3;
//     // width: max-content;
//     font-weight: 500;
//     letter-spacing: 1px;
//     margin-top: 1rem;
//   }

//   &__company {
//     font-size: $font5;
//     color: $black1;
//     font-weight: 600;
//     letter-spacing: 1px;
//   }
//   &__rights {
//     font-size: $font2;
//     color: $black1;
//     letter-spacing: 1px;
//     font-weight: 400;
//   }
//   &__box {
//     background: $black1;
//     border-radius: 1rem;
//     padding: 1.5rem 10vw;
//     margin-top: 3rem;
//     cursor: pointer;
//     color: $brown1;
//     font-size: $font5;
//     font-weight: 700;
//     letter-spacing: 1px;
//     &-image {
//       position: absolute;
//       left: -30px;
//       transform: rotate(2deg);
//       top: -24px;
//       transition: 0.2s all linear;
//       width: 100px;
//       height: auto;
//     }
//   }
//   &__social {
//     &-one {
//       transition: 0.3s all ease-in;
//       margin-right: -8vw;
//       margin-bottom: -5vw;
//       cursor: pointer;
//       z-index: 600;
//       &:hover {
//         transform: rotate(4deg);
//         transition: 0.2s all ease-in;
//       }
//     }
//     &-two {
//       margin-right: -8vw;
//       //   z-index: 501;
//       transition: 0.3s all ease-in;
//       cursor: pointer;
//       &:hover {
//         transform: rotate(-4deg);
//         transition: 0.2s all ease-in;
//       }
//     }
//     &-three {
//       transition: 0.1s all ease-in;
//       cursor: pointer;
//       z-index: 500;
//       margin-left: -8vw;
//       &:hover {
//         transform: rotate(720deg);
//         transition: 0.2s all ease-in;
//       }
//     }
//   }
// }

// .footer__box:hover {
//   .footer__box-image {
//     transform: rotate(8deg);
//   }
// }

// @media screen and (max-width: 992px) {
//   .footer {
//   }
// }
// @media screen and (max-width: 768px) {
//   .footer {
//     &__box {
//       font-size: $font4;
//       font-weight: 600;
//       &-image {
//         width: 50px;
//         top: 0.5rem;
//         left: -1rem;
//       }
//     }
//   }
// }
// @media screen and (max-width: 576px) {
//   .footer {
//     &__heading {
//       // font-family: "Mochiy Pop One", sans-serif !important;
//       font-size: $font12;
//       span {
//         font-size: $font5;
//         font-weight: 600;
//         width: max-content;
//         height: 30px;
//         margin-top: 8px;
//       }
//     }
//   }
// }
