// * COLORS

$white1: #fff;

$brown1: #f7e8d5;
$brown2: #4b4e3d;

$grey1: #f4f6f8;
$grey2: #c4c6c8;
$grey3: #606060;

$black1: #1d1e1c;

// ? COLOR FOR JENNY BOTIQUE
$brownPink: #edd5ce;
// ? COLOR FOR JENNY BOTIQUE

// ? FONTS
$font1: 0.75rem;
$font2: 0.875rem;
$font3: 1rem;
$font4: 1.125rem;
$font5: 1.25rem;
$font6: 1.375rem;
$font7: 1.625rem;
$font8: 1.875rem;
$font9: 2rem;
$font10: 2.125rem;
$font11: 2.25rem;
$font12: 2.375rem;
$font13: 2.625rem;
$font13: 2.875rem;
$font14: 3rem;
$font15: 3.125rem;
$font16: 3.25rem;
$font17: 3.375rem;
$font18: 3.625rem;
$font19: 3.875rem;
$font20: 4rem;

// $grey3: #8a9099;
// $grey4: #70706e;

// $black3: #2d2d2d;

// $brown2: #d5cabb;
// $brown3: #bca792;
// $brown4: #a59a94;
// $brown5: #ceb981;
// $brown7: #cd9562;
// $brown8: #f5ebdc;

// $blue1: #6997bf;
// $blue2: #3b8199;

// $light-blue1: #bfd2d6;
// $light-blue2: #bbd1cc;

// $red1: #b34941;

// $black1: #404e5f;
// $black1: #242b35;
// $black1: #535353;
// $black1: #535353;
// $grey1: #edf2f8;
// $grey1: #e0e1dd;
// $grey5: #f5f5f5;
// $white2: #f0f5f9;
// $grey1: #52616b;
// $grey2: #c9d6df;
// $black1: #1e2022;

// $black1: #0b2a2b;
// $black1: #183d3f;
// $black3: #739495;
// $white1: #f7f8f8;
// $grey1: #333333;
// $orange1: #ff8456;
// $green1: #007048;
// $blue1: #a8eaf5;
// $pink1: #ffb2c2;
// $brown1: #ebdcc3;
// $white2: #f1edea;

// $oran1: #f9ded0;
// $oran2: #ffe8de;
// $oran3: #ed7846;
// $yellow1: #fef8d6;
// $yellow2: #ebbd3d;
// $gre1: #d9fcde;
// $gre2: #1c4946;

// $black1: #010101;
// $black1: #393e45;

// $white1: #fffff7;
// $white2: #fff;

// $grey1: #f3f3f3;
// $grey2: #7b7984;
// $grey3: #e5e5e5;
// $grey4: #eceff1;
// $grey5: #dee2e6;

// $brown1: #fff2de;
// $brown1: #ded9ce;
// $brown2: #82694f;
// $brown3: #edece1;

// $pink1: #fcb2a9;
// $pink2: #f2f2f2;
// $pink2: #f3eae4;
// $pink2: #f5eaee;
// $pink2: #fee0d6;
// $pink3: #ffd6d4;
// $pink4: #fff2f1;
// $pink4: #f3e9e8;

// $green1: #b0d6c1;

// $red1: #ff726f;
// $red2: #ffd2d1;
// $red3: #ffe5e5;

// $pink2: #FEDEDA;
// $pink3: #FDC8C2;
// $pink4: #FDD4CF;
// $pink5: #FEE5E2;
// $pink6: #FFF4F3;

// $pink1: #ffd6d4;
// $pink2: #ff726f;
// $red1: #ef4136;

// $orange1: #f9ba8f;
// $orange2: #c77e00;
// $orange3: #b2642d;
