@import "../../styles/global";

.approach {
  &-hero {
    img {
      height: 50vh;
      object-fit: cover;
    }
  }
  &-intro {
    padding: 4rem 25vw 6rem 25vw;
  }
  &-steps {
    padding: 0 15vw;
    &__box {
      margin-bottom: 8rem;
    }
  }
  &-heading {
    margin-left: 2.5rem;
    text-transform: uppercase;
  }
  &-number {
    font-family: "DM Serif Display", serif !important;
    position: absolute;
    z-index: -1;
    color: $grey2;
    top: -1.75rem;
  }
  &-content {
    font-weight: 300;
  }
}

@media screen and (max-width: 768px) {
  .approach {
    &-intro {
      padding: 4rem 8vw;
    }
    &-steps {
      padding: 0 15px;
      &__box {
        margin-bottom: 5rem;
      }
    }
  }
}
