@import "../../styles/global";

.work {
  &-hero {
    img {
      height: 50vh;
      object-fit: cover;
    }
  }
}
